import React from 'react';
import T from 'prop-types';
import { createRoot } from 'react-dom/client';

import Slide from './Slide';
import Carousel3D from '../../../../components/Carousel3D';


const config = ({
  infinite: true,
  variableWidth: false,
  centerMode: false,
  centerPadding: '0px',
  fade: true,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        dots: false,
        arrows: false,
        fade: false,
        centerMode: true,
        centerPadding: '0px',
        variableWidth: true,
      },
    },
  ],
});

const TweetsSlider = ({ data }) => (
  <Carousel3D
    focusOnLoad
    className="comments-hms-carousel3d"
    config={config}
    slides={data.map(slide => (
      <Slide
        key={slide.id}
        data={slide}
      />
    ))}
  />
);
TweetsSlider.propTypes = {
  data: T.array,
};

export default TweetsSlider;

document.addEventListener('DOMContentLoaded', () => {
  const rootNode = document.getElementById('tweets-slider-root');

  if (rootNode) {
    const slides = [];

    rootNode.querySelectorAll('.tweet-slide').forEach((item) => {
      slides.push(JSON.parse(item.dataset.tweet));
    });
    const root = createRoot(rootNode);
    root.render(<TweetsSlider data={slides} />);
  }
});

// Slide fields
// id
// avatar_src
// name
// username
// tweet_html
// publish_date
