import React from 'react';
import T from 'prop-types';

const Slide = ({
  data,
}) => (
  <div className="comments-slide-content" >
    <div className="data-part">
      <div className="person-info">
        <div className="media-box" >
          <img
            src={data.avatar_src}
            alt={data.name}
            className="media-box-image"
            loading="lazy"
          />
        </div>
        <div className="d-flex flex-column">
          <p className="name">{data.name}</p>
          <p className="company-tag">{data.username}</p>
        </div>
      </div>
      <div className="comment" dangerouslySetInnerHTML={{ __html: data.tweet_html }} />
    </div>
    <div className="date-part">{data.publish_date}</div>
  </div>
);

Slide.propTypes = {
  data: T.object.isRequired,
};

export default Slide;
